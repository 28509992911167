import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/about": [6],
		"/account": [7],
		"/error": [8],
		"/legal": [9,[2]],
		"/legal/[...path]/cookies": [10,[2,3]],
		"/legal/[...path]/copyright": [11,[2,3]],
		"/legal/[...path]/privacyOutline": [13,[2,3]],
		"/legal/[...path]/privacy": [12,[2,3]],
		"/legal/[...path]/sharing": [14,[2,3]],
		"/legal/[...path]/subscriptions": [15,[2,3]],
		"/legal/[...path]/terms": [16,[2,3]],
		"/menu": [17],
		"/playlists": [18],
		"/privacyPolicy": [19],
		"/qrcode": [20],
		"/qrcode/businesscard": [21],
		"/[...catchall]": [5]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';